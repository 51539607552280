import { lazyLoadImage } from '../../base/lazy-load.js';

class MobileHomepageHero {
  constructor() {
    this.$el = $('.mobile-homepage-hero');
    this.$btn = this.$el.find('.search-toggle-btn');
    this.$video = this.$el.find('.background-video');
    this.player = {};

    if(this.$video) {
      this.loadVideo(this.$video);
    } else {
      this.loadImage();
    }
    this.enableButton();
  }

  enableButton() {
    this.$btn.click(() => {
      $('[data-dropdown="book"]').click();
    });
  }

  loadImage() {
    lazyLoadImage(this.$el);
  }

  loadVideo(el) {
    let id = $(el).attr('id');

    if (id) {
      this.player = jwplayer(id).setup({
        file: $(el).data('video-file'),
        playlist: $(el).data('video-playlist'),
        autostart: $(el).data('video-autostart'),
        controls: $(el).data('video-controls'),
        mute: $(el).data('video-mute'),
        repeat: $(el).data('video-loop'),
        stretching: $(el).data('video-stretching') || 'fill',
        height: $(el).data('video-height') || '100%',
        width: $(el).data('video-width') || '100%',
        displaydescription: false,
        displaytitle: false,
        preload: 'auto',
        autoPause: {
          viewability: true,
          pauseAds: true
        }
      })
        //TODO Guessing we need to  keep these callbacks, assuming it is related to tracking
        .on('ready', () => {
          // loop attr causes complete event don't fire
          $(this.player.getContainer()).find('video')
            .removeAttr('loop');
        })
        .on('complete', () => {
          setTimeout(() => {
            $(this.player.getContainer()).find('video')
              .attr('data-is-complete', true);
          }, 1);
        })
        .on('firstFrame', () => {
          $(this.player.getContainer()).find('video')
            .attr('data-is-started', true);
        })
        .on('play', () => {
          this.analyticsMetaData(id);
        });
    }
  }

  analyticsMetaData(id) {
    const playlistItem = jwplayer(id).getPlaylistItem().sources[0];
    let name = playlistItem.file,
      type = playlistItem.type;
    name = name.split('\\').pop()
      .split('/')
      .pop()
      .split('-')
      .shift();

    // Analytics data
    window.digitalData.rewards = {
      activity: {
        videoName: name,
        videoType: type
      }
    };
  }
}

export default new MobileHomepageHero();
