import ConfigsUtils from './config-utils';

const KEY = 'googleMapStyleId';

/**
 * Returns authored googleMapStyleId
 * @see {config-utils.js}
 * @param {string[]} [channels=configIUtils.js default] - The channels for which the AEM Config exists for
 * @param {string[]} [locales=configUtils.js default] - The locales for which the AEM Config exists for
 * @param {string[]} [brands=configUtils.js default] - The brands for which the AEM Config exists for
 */
 const getGoogleMapStyleId = (channels, locales, brands) => {
   const config = ConfigsUtils.findConfig(KEY, channels, locales, brands);
   return config ? config.attributes[KEY] : "9b934a4ab64c971a";
 };

 export default {
   getGoogleMapStyleId,
 };
