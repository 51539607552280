import { EventHandler, getHeaderHeight } from '../base/utils';
import {
  exists,
  getDistanceScrolled,
  isDesktopWidth,
  isMobileWidth,
  ResizeHandler, scrollToAnimated,
  stickify,
} from '../base/dom-utils';

class NavigationBar {
  constructor() {
    this.classes = {
      more: 'left-nav__li-more',
      showMore: 'left-nav__li-more--show',
      notInitialized: 'left-nav__not-initialized',
    };

    this.selectors = {
      // principal elements
      container: '.left-nav-container',
      navBar: '.left-nav',
      navBarMobile: '.left-nav-mobile',
      // booking bar
      bookingBar: '.mini-booking-nav',
      // list items in tablet+
      list: '.left-nav__ul',
      listItems: `.left-nav__ul li:not(.${this.classes.more})`,
      moreItem: `.${this.classes.more}`,
      moreSubList: '.left-nav__more-sub-ul',
      moreCheckbox: '.left-nav__more-checkbox',
    };

    this.dom = {
      $container: $(this.selectors.container),
      $navBar: $(this.selectors.navBar),
      $navBarMobile: $(this.selectors.navBarMobile),
      $bookingBar: $(this.selectors.bookingBar),
      $list: $(this.selectors.list),
      $moreItem: $(this.selectors.moreItem),
      $moreSubList: $(this.selectors.moreSubList),
      $moreCheckbox: $(this.selectors.moreCheckbox),
    };

    if (!this.dom.$container.length) return;
    if (!this.dom.$navBar.length) return;
    if (!this.dom.$navBarMobile.length) return;

    // bind(this) generates a new reference, and to be able
    // to remove the event listener, we need to have the same reference
    this.onResize = this.onResize.bind(this);
    this.handleSticky = this.handleSticky.bind(this);

    this.initialize();
  }

  get navBar() {
    const {$navBar, $navBarMobile} = this.dom;

    if (isMobileWidth()) {
      return $navBarMobile;
    }

    return $navBar;
  }

  get bookingBarBottom() {
    const { $bookingBar } = this.dom;
    const top = $bookingBar.position().top;
    const height = $bookingBar.outerHeight();

    return top + height;
  }

  initialize() {
    this.setStickyOptions();
    this.activateScrollspy();

    EventHandler.one(EventHandler.serviceCall.end, this.onResize);
    ResizeHandler.addResizeEndFn(this.onResize);

    this.dom.$list.on('click', () => {
      this.forceShow = true;
      setTimeout(() => this.forceShow = false, 1000);
    });
  }

  onResize() {
    this.onUnstick();
    this.handleMoreButton();

    $(window).off('scroll', this.handleSticky);
    $(window).on('scroll', this.handleSticky);

    // unhide component after DOM changes
    this.dom.$navBar
      .animate({opacity: 1})
      .removeClass(this.classes.notInitialized);
  }

  setStickyOptions() {
    this.stickyOptions = {
      stick: {},
      unstick: {},
      start: this.navBar.offset().top,
      onStuck: () => this.onStuck(),
      onUnstick: () => this.onUnstick(),
    };
  }

  // triggers while sticky during scrolling
  onStuck() {
    // < 0 is up; > 0 is down
    const dir = $(window).scrollTop() - getDistanceScrolled();
    const isDesktop = isDesktopWidth();
    const headerHeight = getHeaderHeight();
    const { $moreCheckbox } = this.dom;

    if (dir < 0 || this.forceShow) {
      this.navBar.css({
        top: isDesktop ? 0 : this.bookingBarBottom,
        height: isDesktop ? `${headerHeight - 2}px` : 'auto'
      });
    } else {
      const height = this.navBar.outerHeight();

      this.navBar.css({
        top: isDesktop ? `-${headerHeight}px` : `${this.bookingBarBottom - height}px`,
      });

      $moreCheckbox.prop('checked', false);
    }
  }
  // is not sticky
  onUnstick() {
    this.navBar.removeClass('stick sticky stuck').removeAttr('style');
  }

  handleSticky() {
    stickify(this.navBar, this.stickyOptions);
  }

  handleMoreButton() {
    const { listItems } = this.selectors;
    const $listItems = $(listItems);
    const { $moreItem, $moreSubList } = this.dom;
    let offsetTopPrev;

    const firstWrappedIdx = [...$listItems].findIndex((el, i) => {
      const offsetTop = $(el).offset().top;
      const result = i > 0 && offsetTop > offsetTopPrev;

      offsetTopPrev = offsetTop;

      return result;
    });

    if (firstWrappedIdx === -1) return;

    const $wrappedItems = [...$listItems].slice(firstWrappedIdx - 1);

    $wrappedItems.forEach((el) => {
      $moreSubList.append(el.outerHTML);
      $(el).remove();
    });

    $moreItem.addClass(this.classes.showMore);

    this.activateScrollspy();
  }

  // This code was copied over from left-nav.js
  activateScrollspy() {
    let offset = getHeaderHeight();

    if (exists($('#bookingBarHeight'))) {
      offset += $('#bookingBarHeight').outerHeight();
    }

    $('body').scrollspy({
      target: '.bs-docs-sidebar',
      offset: offset + 20
    });

    $('.bs-docs-sidebar a, .all-ame-link a, .all-ame-link-mobile').click((e) => {
      e.preventDefault();

      // TODO: Refactor when possible
      // Grouping variable declarations is a very old-school practice
      // which is obsolete in modern JS
      let id = $(e.currentTarget).attr('href'),
        $target = exists($(id)) ? $(id) : null,
        mobileOffset = 0;

      if (exists('.property-page')) {
        let alertHeight = 0;
        if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
          alertHeight = $('.alert-component').height();
        }
        mobileOffset += alertHeight;
        if (isMobileWidth()) {
          mobileOffset += $('#bookingBar__mini').outerHeight();
        }
      }

      if (exists($target)) {
        scrollToAnimated($target.offset().top - (offset + mobileOffset));
      }

      if ($(e.currentTarget).closest('ul')
        .hasClass('nav-stacked')) {
        var ele = e.currentTarget;
        setTimeout(function() {
          $('#sidebar li').removeClass('active');
          $(ele).closest('li')
            .addClass('active');
        }, 500);
      }

      if ($(e.currentTarget).closest('p')
        .hasClass('all-ame-link')) {
        let id = $(e.currentTarget).attr('href');
        setTimeout(function() {
          $('#sidebar li').removeClass('active');
          $('#sidebar li a[href="' + id + '"]').parent('li')
            .addClass('active');
        }, 500);
      }
    });

    if (isMobileWidth()) {
      $('.bs-docs-sidebar').on('activate.bs.scrollspy', (e) => {
        $('.left-nav-mobile__header p').html($(e.target).find('a')
          .html());
      });
    }
    $('body').on('touchmove', () => {
      if ($('.left-nav-mobile .navbar-header__trigger').attr('aria-expanded') == 'true') {
        $('.navbar-header__trigger').trigger('click');
      }
    });
  }
}

export default new NavigationBar();
