
import {
  isMobileWidth,
  simpleLightbox,
  getWindowHeight,
  ResizeHandler
} from '../base/dom-utils.js';
import {
  $_PAGE_,
  $_GENERIC_LIGHTBOX_,
  _LOCALE_,
  _defaultChannelId,
  overview_propertyId,
  overview_brandId,
  overview_orsId,
  additionalCorpCodes,
  wrccCorpCodes,
  countryCodeList,
  memberRatePlanList,
  wrccRateCodes,
  property_country_code,
  property_state_code,
} from '../base/vars.js';
import BNPLUplift from '../components/bnpl-uplift.js';

import {
  setCriteria,
  getCriteria,
  setSessionStorage,
  getSearchOverview,
  setRoomsRates,
  removeRoomsRates,
  isWRCCHolder,
  getBrandTier,
  getBrand,
  goToBook,
  goToPackages,
  goToRoomsRates,
  UserHandler,
  bookDataBeforeSSOLogin
} from '../base/session-handler.js';
import {
  _isNotNull,
  _isNull,
  _isWeakTrue,
  formatDateForBWSAvailability,
  getQueryParameter,
  callService,
  isLoyaltyRP,
  isAllInPriceRegion,
  toMoney,
  formatNumber,
  formatDateForPrinting,
  formatDateForUplift,
  isBNPLEX,
  getDateFromDateString,
  getCurrencyMapping,
  getNumDays,
  getServiceUrl,
  isBarRate,
  isMemberRate,
  isWRCCRate,
  CookieHandler,
  EventHandler,
  sortRoomRatePlans,
  isStrikeThroughRateEnable,
  convertToCurrency,
  getSelectedCurencyCode,
  isFeesInclude,
  printDollarAmount
} from '../base/utils.js';
import { lazyLoadImageInViewport } from '../base/lazy-load.js';
import Thinking from '../base/thinking.js';
import Analytics from '../base/wyn-analytics-module.js';
import Handlebars from 'handlebars';
import BookingSummary from '../components/booking-summary.js';
import RateDetails from '../components/rate-details.js';
import ConfigsUtils from '../base/aem-configs/config-utils.js';
import Login from './login.js';


class FeaturedRoomsRates {

  constructor() {
    this.$mainEl = $('.featured-rooms-rates-main');
    this.ratesArr = [];
    this.overViewServicePromise = $.Deferred();
    this.propertyAvailabilityServicePromise = $.Deferred();
    this.addonsCategories = ConfigsUtils.getConfigs('add-ons-categories',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);
    if ($_PAGE_.is('.property-page')) {
      removeRoomsRates();
    }

  }

  componentExist() {
    return this.$mainEl.length > 0;
  }

  init(isEditDateChange) {
    if (this.componentExist()) {
      const element = document.querySelector('#featured-rooms-rates');
      const options = {root: null, rootMargin: "0px", threshold: 0.2 };
      
      if(isEditDateChange){
        this.propertyAvailabilityServicePromise = $.Deferred();
        Promise.all([this.propertyAvailabilityServicePromise]).then(() => {
          this.showComponentLoading();
          //Add IntersectionObserver listener
          let observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
          observer.observe(element);
        }).catch(() => {
          this.hideComponent();
        });
      } else{
        Promise.all([this.overViewServicePromise, this.propertyAvailabilityServicePromise]).then(() => {
          this.showComponentLoading();
          //Add IntersectionObserver listener
          let observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
          observer.observe(element);
        }).catch(() => {
          this.hideComponent();
        });
      }


      EventHandler.on(EventHandler.rates.propertyAvailability.error, () => {
        this.propertyAvailabilityServicePromise.reject();
      });

      EventHandler.on(EventHandler.rates.propertyAvailability.ratesUnavailable, () => {
        this.propertyAvailabilityServicePromise.reject();
      });

      EventHandler.on(EventHandler.rates.propertyAvailability.ratesAvailable, () => {
        this.propertyAvailabilityServicePromise.resolve();
      });

      EventHandler.on(EventHandler.property.search.success, () => {
        this.overViewServicePromise.resolve();
      });
    }
  }

  //IntersectionObserver callback
  handleIntersect(entries, observer) {
    for (var i = 0; i < entries.length; i++) {
      if (entries[i].isIntersecting){
        this.loadComponent();
        observer.unobserve(entries[i].target);
      }
    }
  }

  loadComponent() {
    if(this.componentExist()) {
      this.totalRates = -1;
      this._roomsAndRates = {};
      this._roomsToDisplay = [];
      this._overviewData = getSearchOverview() || {};
      this._isPointsRateSelected = false;//initParams() will check and update this
      //Messages displayed above the rate row (eyebrow styled)
      this._rateDetailTextUnAuth = this.$mainEl.find('.rooms-slide').data('memberRateTextUnauth');
      this._rateDetailTextAuth = this.$mainEl.find('.rooms-slide').data('memberRateTextAuth');
      this._rateDetailTextWrcc = this.$mainEl.find('.rooms-slide').data('memberRateTextWrccmember');
      this._roomLeftMessage = this.$mainEl.find('.rooms-slide').data('roomLeftMessage');
      this._roomsLeftMessage = this.$mainEl.find('.rooms-slide').data('roomsLeftMessage');
      //Message displayed when user tries to move on to booking page and has not Enough points
      this._notEnoughPointsMessage = this.$mainEl.find('.rooms-slide').data('notEnoughPointsMessage');
      if (countryCodeList && isAllInPriceRegion()) {
        this._emeaRegion = true;
      }
      this.getRoomsData().then((res) => {
        this.sortAndReduceRooms();
        this.getRoomsImages().then(() => {
          this.displayRooms();
          this.bindImageLazyLoad();
          this.bindLinkModals();
          this.bindCTA();
          this.updateAnalytics();
        });
      }).fail((res) => {
        this.hideComponent();
      });
    }
  }

  initParams() {
    if(overview_propertyId){
      setCriteria({
        propertyId: overview_propertyId
      });
    }
    this._params = $.extend(true, {}, getCriteria());
    if (isNaN(parseInt(this._params.propertyId.charAt(0)))) {
      // If first character is a letter, format is WY##### instead of #####.
      // Strip first 2 characters to avoid breaking services
      this._params.propertyId = this._params.propertyId.slice(2);
    }
    this._params.orsId = overview_orsId || this._params.orsId;
    this._params.altPropId = overview_orsId || this._params.altPropId;
    this._params.brandId = overview_brandId || this._params.brandId;

    //check-in and check-out logic
    this._params.checkInDate = formatDateForBWSAvailability(this._params.checkInDate);
    this._params.checkOutDate = formatDateForBWSAvailability(this._params.checkOutDate);
    if (this._params.useWRPoints && _isWeakTrue(this._params.useWRPoints)) {
      setCriteria({useWRPoints: true});
      this._isPointsRateSelected = true;
    } else {
      this._isPointsRateSelected = false;
      setCriteria({useWRPoints: false});
    }

    let warnParamValue = getQueryParameter('groupCode');
    if (warnParamValue && (warnParamValue.length > 0)) {
      this.isGroupCodeExist = true;
    }
  }

  //Params for Room and Rates
  getParamsForService() {
    this.initParams();
    let p = {};
    p.brand_id = this._params.brandId;
    p.checkout_date = this._params.checkOutDate;
    p.checkin_date = this._params.checkInDate;
    p.adults = this._params.adults;
    p.children = this._params.children;
    p.childAge = this._params.childAge;
    p.rooms = this._params.rooms;
    p.ratePlan = this._params.ratePlan;
    p.rate_code = this._params.rateCode;
    p.group_code = this._params.groupCode;
    p.corporate_id = this._params.corpCode;

    //Add additionalCorpCodes param for mobile view only
    if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
      p.additionalCorpCodes = additionalCorpCodes;
    }

    if(isWRCCHolder() && _isNotNull(wrccCorpCodes)) {
      if(p.additionalCorpCodes) {
        p.additionalCorpCodes = p.additionalCorpCodes + '|' + wrccCorpCodes;
      } else {
        p.additionalCorpCodes = wrccCorpCodes;
      }
    }

    p.propertyId = this._params.propertyId;
    p.sorting = this._params.sorting;
    p.isOrsId = this._params.orsId;
    p.altPropId = this._params.altPropId;
    p.region = this._params.region;
    p.useWRPoints = this._params.useWRPoints;
    p.language = _LOCALE_;

    //FNS OR POINTS use cases - START
    if (isLoyaltyRP(p.rateCode) || _isWeakTrue(p.useWRPoints)) {
      p.useWRPoints = true;
      this._isFNS = true;
    }

    //FNS OR POINTS use cases - END
    if (p.brandId === 'WY') {
      p.isOrsId = true;
      p.altPropId = p.orsId;
    }
    p.useWRPoints = getCriteria().useWRPoints;
    if (_isWeakTrue(p.useWRPoints)) {
      p.corpCode = p.groupCode = p.rateCode = p.ratePlan = '';
    } else {
      if (_isNotNull(p.corpCode) || _isNotNull(p.groupCode) || _isNotNull(p.rateCode)) {
        $('[for=wyndham-rewards-filter]').off('click')
          .addClass('disabled');
        p.useWRPoints = false;
      } else {
        p.useWRPoints = true;
      }
    }
    return p;
  }

  getRoomsData() {
    let parameters = this.getParamsForService();
    let promise = $.Deferred();
    Thinking.addIgnore('getRoomsAndRates');
    callService('roomsRates', parameters, 'GET', {}, true).then((response) => {
      setRoomsRates(response.roomsAndRates, true);
      let sortedRoomsAndRates = response.roomsAndRates;
      const standardRooms = sortedRoomsAndRates.rooms.filter(room => !room.accessible);
      const accessibleRooms = sortedRoomsAndRates.rooms.filter(room => room.accessible);
      //Merge rooms in order standard rooms and accessible rooms
      sortedRoomsAndRates.rooms = [...standardRooms, ...accessibleRooms];
      this._roomsAndRates = sortedRoomsAndRates;
      promise.resolve();
    }).fail((err) => {
      promise.reject();
    });
    return promise;
  }

  getRoomsImages() {
    let promise = $.Deferred(),
        pr = {};

    pr.locale = _LOCALE_;
    pr.brandId = getBrand(true);
    pr.hotelId = overview_propertyId || getCriteria().propertyId;
    pr.country = property_country_code;
    pr.stateProv = property_state_code;
    pr.roomTypes = '';
    pr.brandTier = getBrandTier().toUpperCase();
    let roomTypesArr = [];

    $.each(this._roomsToDisplay, (i, rm) => {
      if (!($.inArray(rm.roomTypeCode, roomTypesArr) >= 0)) {
        roomTypesArr.push(rm.roomTypeCode);
      }
    });

    $.each(roomTypesArr, (ind, roomType) => {
      pr.roomTypes += roomType + ',';
    });

    Thinking.addIgnore('propertyRoomImage.json');
    callService(getServiceUrl('getRoomImage'), pr, 'GET', {}, true).then((imres) => {
      if (imres) {
        this._roomImageData = imres;
        promise.resolve();
      }
    }).fail((error) => {
      promise.resolve();
    });
    return promise;
  }

  displayRooms() {
    if (_isNotNull(this._roomsAndRates) && _isNotNull(this._roomsToDisplay)) {
      this._roomsToDisplay = sortRoomRatePlans(this._roomsToDisplay, getCriteria(), UserHandler.isWRLoggedIn(), isMobileWidth());
      this._roomsToDisplay.forEach((room) =>{

        //Fallback roomName for WY
        if (overview_brandId === 'WY' && !_isNotNull(room.shortName) && room.description) {
          room.shortName = (((room.description).replace(/-/, '').split(/\s+/).slice(0, 3).join(' ')) + '..');
        }
        //Complete Images data once loaded
        if (this._roomImageData && this._roomImageData[room.roomTypeCode]) {
          room.image = this._roomImageData[room.roomTypeCode][0];
        }


        // Find total number of rates
        for (let i = 0; i < room.length; i++) {
          this.totalRates += room[i].rates.length;
        }

        //Complete Rates info to further display
        room.rates.forEach((rateData, i) => {
          this.totalRates += 1;
          this.populateRates(rateData, room);
        });
        //Set strikeThroughRates
        this.setStrikeThroughRates(room.rates);
        //Display inventory
        this.updateInventory(room);
        //Update rates order
        this.handleDuplicatedMemberRate(room);

      });
      this.reduceRatesToDisplay();
      //Compile rooms listing
      let source = $('#rooms-rates-tmpl').html();
      let template = Handlebars.compile(source);
      let html = template(this._roomsToDisplay);
      //Display component
      this.$mainEl.find('#loader').removeClass('loader');
      this.$mainEl.find('.rooms-slide.by-room').html(html);
      this.$mainEl.find('.rooms-slide.by-room').removeClass('hide');
      this.$mainEl.find('.see-all-rates-wrapper').removeClass('hide');


      // Complete Rates info to further display
      this._roomsToDisplay.forEach((room) =>{
        room.rates.forEach((rateData, i) => {
          $('#general-rate-list .points-rate').each((i, el) => {
            if ($(el).attr('rate') === 'SRB6' || 
                $(el).attr('rate') === 'SRB7' || 
                $(el).attr('rate') === 'SRB8') {
                $(el).hide();
            } else {
                $(el).show();
            }       
          });

          $('#srb-rate-list .points-rate').each((i, el) => {
            if ($(el).attr('rate') === 'SRB6' || 
                $(el).attr('rate') === 'SRB7' || 
                $(el).attr('rate') === 'SRB8') {
                $(el).show();
            } else {
              $(el).hide();
            }        
          });

          $('#srb-rate-list .cash-rate').each((i, el) => {
              $('.rate-list-grid-wrapper').hide();      
          });

          // styles for ipad
          let hasSrb6, hasSrb7, hasSrb8;
          

          if ($("#srb-rate-list").find('.rate.points-rate')
            .length > 0){
              hasSrb6 = $("#srb-rate-list").find('li[rate="SRB6"]').length > 0;
              hasSrb7 = $("#srb-rate-list").find('li[rate="SRB7"]').length > 0;
              hasSrb8 = $("#srb-rate-list").find('li[rate="SRB8"]').length > 0;
          }

          const count = [hasSrb6, hasSrb7, hasSrb8].filter(Boolean).length;

          const width = $(window).width();

          if(count === 0){
              $('.rate-list-grid-wrapper').hide();
          } else if (count === 1) {
            if (($(".rate-list-grid-wrapper").find('#srb-rate-list')
              .length > 0) && (width >= 720 && width <= 1199)){
                $('[id="srb-rate-list"]').css({
                  display: "grid",
                  "grid-template-columns": "1fr"
                });
                $('[id="srb-rate-list"]').addClass('single-srb-ipad');
            }
          } else if (count >= 2) {
            if (($(".rate-list-grid-wrapper").find('#srb-rate-list')
              .length > 0) && (width >= 720 && width <= 1199)){
                $('[id="srb-rate-list"]').addClass('two-srb-ipad');
                $('#srb-rate-list .points-rate .rate').addClass('two-srb-rate-ipad');
            }
            if (($(".rate-list-grid-wrapper").find('#srb-rate-list')
              .length > 0) && (width >= 720)){
                $('#srb-rate-list .points-rate .unit-per-night').addClass('pernight-cutthrough');
            }
            if (count === 2) {
              if (($(".rate-list-grid-wrapper").find('#srb-rate-list')
                .length > 0) && (width >= 1200)){
                  $('#srb-rate-list .points-rate .rate').addClass('two-srb-desktop-pernight');
              }
            }
            if (count === 3) {
              if (($(".rate-list-grid-wrapper").find('#srb-rate-list')
                .length > 0) && (width >= 1200)){
                  $('#srb-rate-list .points-rate .rate').addClass('three-srb-rate-ipad');
              }
            }
          }
        });
      });

      if(!isBNPLEX(getSearchOverview())){
        BNPLUplift.handleConfig({
          currencyCode: this.currencyCode,
          checkinDate: formatDateForUplift(getCriteria().checkInDate),
          checkoutDate: formatDateForUplift(getCriteria().checkOutDate),
          checkout: false
        });

        var orderInfo = {
          hotel_reservations: [
            {
              "hotel_name": this._params.brandId,
              "check_in": formatDateForUplift(getCriteria().checkInDate),
              "check_out": formatDateForUplift(getCriteria().checkOutDate)
            },
          ],
          merchant: window.brand,
          merchant_field_1 : "Wyndham Hotels & Resorts",
          merchant_field_2 : window.brand,
        };
      } else {
        BNPLUplift.trackBNPLBrandStatus(this.currencyCode);
      }

      if(window.upReady) {
        try{
          window.Uplift.Payments.load(orderInfo);
        }catch(err) {
          return false;
        }
      }
    }
  }

  //Make sure only one rate of the Member type is displayed
  handleDuplicatedMemberRate(room) {
    if (isWRCCRate(room.rates[0].ratePlanId)) {
      if (room.hasCugWrccRate && room.hasCugMemberRate) {
        //Remove '.cug-member-rate'
        room.rates = room.rates.filter(item => item.isCugMemberRate !== true);
      }
    } else if (room.hasCugWrccRate && room.hasCugMemberRate) {
      let currentIndex = room.rates.findIndex(rateData => rateData.isCugWrccRate === true);
      let indexToMove = room.rates.findIndex(rateData => rateData.isCugMemberRate === true);
      this.arrayMove(room.rates, currentIndex, indexToMove);
      //Remove '.cug-member-rate'
      room.rates = room.rates.filter(item => item.isCugMemberRate !== true);
    }
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

  setStrikeThroughRates(rates) {

    if (isStrikeThroughRateEnable(this._overviewData) && !UserHandler.isWRLoggedIn()) {
      let roomBarRate = rates.find(rate => {
        return isBarRate(rate.ratePlanCode);
      });
      rates.forEach((rateData, i) => {
        if (isMemberRate(rateData.ratePlanCode) && _isNotNull(roomBarRate)) {
          rateData.moneyToDisplay = roomBarRate.moneyToDisplay;
          rateData.moneyDecimals = roomBarRate.moneyDecimals;
          //let tempRatePrice = convertToCurrency(Number(rateData.moneyToDisplay + "." + rateData.moneyDecimals), rates.currencyCode);
          rateData.cls += ' striked-rate';

          //Uplift strikethrough rate
          if(!BNPLUplift.isRatePlanDisabled(rateData.ratePlanCode)){
            rateData.upliftRate = roomBarRate.moneyToDisplay.replace('$', '') + roomBarRate.moneyDecimals;
          }
        }
      });
    }
  }

  updateInventory(room) {
    //populate rate plan level data
    if (!this.isGroupCodeExist & room.inventoryCount <= 5 && room.inventoryCount > 0) {
      let warnMsg = '';
      (room.inventoryCount == 1) ? (warnMsg = this._roomLeftMessage) : (warnMsg = this._roomsLeftMessage);
      room.warnText = warnMsg.replace('[TotalRooms]', room.inventoryCount);
    }
  }

  populateRates(rateData, room) {
    let rateCode = rateData.ratePlanId,
        emeaRegionFlag = false,
        rateIncludingTaxes;
    let rateSeg = this._roomsAndRates.ratePlans.find(rate => {
      return rate.RatePlanCode == rateCode || rate.ratePlanId == rateCode;
    });
    rateData.ratePlanCode = rateSeg.RatePlanCode;
    rateData.ratePlanDescription = rateSeg.RatePlanDescription;
    rateData.rateNameToDisplay = rateSeg.RatePlanName;
    rateData.isPoints = (rateData.pacRatePlan || rateData.fnsRatePlan);
    rateData.isCash = !rateData.isPoints;
    //Check Rates for authenticated users
    if (memberRatePlanList && (memberRatePlanList.indexOf(rateData.ratePlanId) > -1)) {
      rateData.memberRate = true;//Add classes cug-rate cug-member-rate
      room.hasCugMemberRate = true;
      rateData.isCugMemberRate = true;
      rateData.cls = ' cug-rate cug-member-rate';
      rateData.detailRateText = CookieHandler.cidAuthenticated() ? this._rateDetailTextAuth : this._rateDetailTextUnAuth;
    } else if(wrccRateCodes && (wrccRateCodes.indexOf(rateData.ratePlanId) > -1)) {
      rateData.wrccRate = true;//Add classes cug-rate cug-wrcc-rate
      rateData.cls = ' cug-rate cug-wrcc-rate';
      room.hasCugWrccRate = true;
      rateData.isCugWrccRate = true;
      rateData.detailRateText = (CookieHandler.cidAuthenticated() && isWRCCHolder()) ? this._rateDetailTextWrcc : '';
    } else if (CookieHandler.cidAuthenticated() && isWRCCHolder() && rateData.fnsRatePlan) {
      rateData.wrccRate = true;
      rateData.detailRateText = this._rateDetailTextWrcc;
    }
    if (this._emeaRegion && (rateData.averageToDisplay > 0)) {
      rateData.taxesFees = true;//$('.pricing .taxes-fees', $rrc).removeClass('hidden');
      emeaRegionFlag = true;
      rateIncludingTaxes = rateData.averageToDisplay;
    } else {
      rateIncludingTaxes = rateData.averageToDisplay;
    }

    if (isFeesInclude(rateData.priceDisplayType) && rateData.priceDisplayType !== 'CMA') {
      rateData.fees = true;//$('.pricing .fees', $rrc).removeClass('hidden');
      rateData.taxesFees = false;
      rateIncludingTaxes = rateData.averageToDisplay;
    }else if(rateData.priceDisplayType === 'CMA'){
      rateData.taxesFees = true;
      rateData.fees = false;
      rateIncludingTaxes = rateData.averageToDisplay;
    }else{
      rateData.taxesFees = false;
      rateData.fees = false;
      rateIncludingTaxes = rateData.averageToDisplay;
    }

    //Points
    if (rateData.fnsRatePlan) {
      if (CookieHandler.cidAuthenticated() && isWRCCHolder()) {
        rateData.cls += ' striked-rate cug-rate cug-wrcc-rate';
        room.hasCugWrccRate = true;
        rateData.isCugWrccRate = true;
      }
      rateData.pointsToDisplay = formatNumber(parseInt(rateData.fnsPoints));
      room.isSRBMatch = true;
    } else if (rateData.pacRatePlan) {
      rateData.isPacRate = true;
      if (rateIncludingTaxes === 0) {
        return;
      }
      let moneySplit = toMoney(rateIncludingTaxes).split('.');

      rateData.moneyToDisplay = "+ " + getCurrencyMapping(rateData.currencyCode, false) + moneySplit[0];
      rateData.moneyDecimals = moneySplit[1];
      rateData.showPointsPerNight = true;
      rateData.plusPoints = formatNumber(rateData.pacPoints);

      let checkIn = this._params.checkInDate.split('-');
      rateData.checkInFormat = checkIn[2] + checkIn[0] + checkIn[1];
      if(!BNPLUplift.isRatePlanDisabled(rateData.ratePlanId)){
        rateData.upliftRate = moneySplit[0] + moneySplit[1];
      }
      rateData.roomCode = overview_propertyId;
      rateData.roomName = room.shortName;
      rateData.reservationType = BNPLUplift.isPrepaidCodes(rateData.ratePlanId);
    } else {
      if (rateIncludingTaxes === 0) {
        return;
      }
      if (rateData.cugRate && !UserHandler.isWRLoggedIn()) {
        let barRate = room.rates.filter((el) => {
          return isBarRate(el.ratePlanId);
        })[0];
        let nextLowRate = (barRate ? (emeaRegionFlag ? barRate.averageAfterTax : barRate.averageBeforeTax) : rateIncludingTaxes);
        let unroundedPercent = (1 - (parseFloat(rateIncludingTaxes) / parseFloat(nextLowRate))) * 100;
        // Handle for floating point errors; round down in all cases except if the number should be a round integer.
        let percentOff = (unroundedPercent % 1 > 0.999) ? Math.round(unroundedPercent) : Math.floor(unroundedPercent);
        rateData.offText = percentOff + ' % OFF';
        let moneySplit = toMoney(rateIncludingTaxes).split('.');
        rateData.moneyToDisplay = getCurrencyMapping(rateData.currencyCode, false) + moneySplit[0];
        rateData.moneyDecimals = moneySplit[1];
      } else {
        let moneySplit = convertToCurrency(toMoney(rateIncludingTaxes), rateData.currencyCode).toString().split('.');
        rateData.moneyToDisplay = (getSelectedCurencyCode(rateData.currencyCode) == 'USD' ? '$' : '') + moneySplit[0];
        rateData.moneyDecimals = moneySplit[1];
        room.isRateMatch = true;
        if(getSelectedCurencyCode('')){
          rateData.conversionCode = getSelectedCurencyCode('rateData.currencyCode');
        } else {
          rateData.conversionCode = rateData.currencyCode;
        }
      }
    }

    // Add price to uplift data attribute
    let roomRate = rateIncludingTaxes.toString();
    let upliftRate;

    if (roomRate.includes('.')) {
      var roomSplitRate = roomRate.split('.');

      if (roomSplitRate[1].length == 1) {
        roomSplitRate[1] += '0';

      }

      upliftRate = roomSplitRate[0] + roomSplitRate[1];

    } else {
      upliftRate = roomRate + '00';
    }

    let checkIn = this._params.checkInDate.split('-');
    rateData.checkInFormat = checkIn[2] + checkIn[0] + checkIn[1];
    if(!BNPLUplift.isRatePlanDisabled(rateData.ratePlanId)){
      rateData.upliftRate = upliftRate;
    }
    rateData.roomCode = overview_propertyId;
    rateData.roomName = room.shortName;
    rateData.reservationType = BNPLUplift.isPrepaidCodes(rateData.ratePlanId);

    if(rateData.currencyCode === 'USD') {
      rateData.currencySymbol = '$';
    }
  }


  bindLinkModals() {
    var self = this;
    $('.featured-rooms-rates-main a.room-detail-link').on('click', function(e) {
      e.preventDefault();
      let roomTypeCode = $(this).parents('.room').attr('room');
      let imageDiv = $(this).parents('.room').find('.room-img-wrapper img');
      let imagePath = imageDiv.attr('src');
      self.displayRoomModal(roomTypeCode, imagePath);
    });

    $('.featured-rooms-rates-main a.addl-details').on('click', function(e) {
      e.preventDefault();
      let roomTypeCode = $(this).parents('.room').attr('room');
      let ratePlanId = $(this).parents('li.rate').attr('rate');
      self.displayRateDetailsModal(roomTypeCode, ratePlanId);
    });

    $('.featured-rooms-rates-main a.stay-total').on('click', function(e) {
      e.preventDefault();
      let roomTypeCode = $(this).parents('.room').attr('room');
      let ratePlanId = $(this).parents('li.rate').attr('rate');
      self.displayStayTotalModal(roomTypeCode, ratePlanId);
    });

  }

  bindImageLazyLoad() {
    //Bind images
    lazyLoadImageInViewport('.room img.lazy-load');
    ResizeHandler.addResizeEndFn(() => {
      lazyLoadImageInViewport('.room img.lazy-load');
    });
  }

  bindCTA() {
    //Rates price action
    this.$mainEl.find('.book-now-cta').on('click', async(e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      let $currentEl = $(e.currentTarget);
      let roomTypeCode = $currentEl.parents('.room').attr('room');
      let ratePlanId = $currentEl.parents('li.rate').attr('rate');
      let rImg = $currentEl.parents('.room').find('img').eq(0).attr('src');
      let roomSelected = this.getRoom(roomTypeCode);
      let rateSelected = this.getRateFromRoom(roomSelected, ratePlanId);
      let totPoints = parseInt(rateSelected.totalFnsPoints);

      if (rateSelected.isPoints) {
        if (UserHandler.isWRLoggedIn()) {
          let userPts = UserHandler.getLimitedProfile().pointBal;
          if ((rateSelected.fnsRatePlan && userPts < parseInt(totPoints)) ||
                (rateSelected.pacRatePlan && userPts < parseInt(rateSelected.pacPoints))) {
                simpleLightbox(this._notEnoughPointsMessage);
          } else {
            this.fetchPackages(roomSelected.roomTypeCode,
              rateSelected.ratePlanId,
              rateSelected.corpCode,
              rateSelected.autoEnroll,
              rateSelected.qualPointsEarned,
              rateSelected.pacRatePlan,
              rateSelected.totalAfterTax,
              rImg,
              false,
              this._overviewData.directBillSupported || false);
          }
        } else {
          this.promptLogin();
        }
      } else if (rateSelected.cugRate && !UserHandler.isWRLoggedIn()) {
        this.promptLogin();
      } else {
        this.fetchPackages(roomSelected.roomTypeCode,
          rateSelected.ratePlanId,
          rateSelected.corpCode,
          rateSelected.autoEnroll,
          rateSelected.qualPointsEarned,
          rateSelected.pacRatePlan,
          rateSelected.totalAfterTax,
          rImg,
          false,
          this._overviewData.directBillSupported || false, rateSelected.currencyCode);
      }
    });

    //See all button
    this.$mainEl.find('.all-btn').click((e) => {
      goToRoomsRates();
    });
  }

  fetchPackages(rt, rp, cc, ae, qp, pac, at, rImg,isModify,directBillSupported,currencyCode) {
    let data = {
      "checkin_date": this._params.checkInDate,
      "checkout_date": this._params.checkOutDate,
      "rate_plan": rp,
      "room_type_code": rt,
      "propertyId": this._params.propertyId,
      "adults": this._params.adults,
      "children":this._params.children,
      "childAge":this._params.childAge,
      "language":_LOCALE_,
      "noOfRooms":this._params.rooms,
      "requested_currency_code":currencyCode
    };

    if(this._params.rooms > 1 || (_isNull(data.propertyId) || data.propertyId === '')){
      setSessionStorage('added-package',{},true);
      goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
    }else{
      callService('getPackages', $.param(data), 'GET').then((res) => {
        if (res && res.status === 'OK' || res.services && res.services.length > 0){
          let isCategoryHasPackage = false;
          $.each(this.addonsCategories[0],(counter,category)=>{
            isCategoryHasPackage = isCategoryHasPackage || res.services.reduce((result , item) => {
              return item && item.comments && item.comments.category === JSON.parse(category)[_LOCALE_] ? true : result;
            },null);
          });
          if(isCategoryHasPackage){
            goToPackages(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
          }else{
            goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
          }
        }else{
          goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
        }
      });
    }
  }

  displayRoomModal(roomTypeCode, imagePath) {
    let room = this.getRoom(roomTypeCode);
    //Check if the amenities where not computed already
    if (_isNull(room.amenities)) {
      room.amenities = [];
      room.accesibleAmenities = [];
      let roomsData = getSearchOverview();
      if (_isNotNull(roomsData) && roomsData.roomAmenities) {
        let roomSelected = roomsData.roomAmenities.find(room => {
          return room.roomType == roomTypeCode;
        });
        //Get Room Amenities
        if (roomSelected && roomSelected.amenitiesDetails.length > 0) {
          roomSelected.amenitiesDetails.forEach((amenity) => {
            amenity.groupDetails.forEach((amenityItem) => {
              let dtxt = (amenityItem.amenityCharge === 'Y') ? '*' : '';
              room.amenities.push(amenityItem.amenityName + dtxt);
            });
          });
        }
        //Get Accesible amenities
        if (roomSelected && roomSelected.featuresDetails.length > 0) {
          roomSelected.featuresDetails.forEach((amenityItem, i) => {
            room.accesibleAmenities.push(amenityItem.featureName);
          });
        }
        //Sort Room amenities
        room.amenities.sort((a, b) => {
          let x = a.toLowerCase(),
            y = b.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });
        room.accesibleAmenities.sort((a, b) => {
          let x = a.toLowerCase(),
            y = b.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }
    }
    //Compile template
    room.roomModalImgPath = imagePath;
    let source = $('#rooms-detail-tmpl').html();
    let template = Handlebars.compile(source);
    this.showLightbox(template(room));
  }

  displayRateDetailsModal(roomTypeCode, ratePlanId){
    let room = this.getRoom(roomTypeCode);
    let rate = this.getRateFromRoom(room, ratePlanId);
    //Compile template
    let source = $('#rate-details-tmpl').html();
    var template = Handlebars.compile(source);
    this.showLightbox(template(rate));
  }

  displayStayTotalModal(roomTypeCode, ratePlanId) {
    let room = this.getRoom(roomTypeCode);
    let rate = this.getRateFromRoom(room, ratePlanId);
    this.populateRoomRateTax(rate);
    this.populateBreakDown(rate);
    this.showTaxModal();
  }

  populateRoomRateTax(rateData) {
    let pdata = this._overviewData,
      pol = {},
      checkInDate = getDateFromDateString(this._params.checkInDate),
      checkOutDate = getDateFromDateString(this._params.checkOutDate);
    //populate the values
    $.each(pdata.hotelPolicy, (counter, poli) => {
      if ((poli).replace(/\s/g, '-').toLowerCase()
        .match(/check-in-time/)) {
        pol.checkInDate = poli.split('#')[1];
      }
      if ((poli).replace(/\s/g, '-').toLowerCase()
        .match(/check-out-time/)) {
        pol.check_out = poli.split('#')[1];
      }
    });
    pol.checkInDate = (pol.checkInDate) ? pol.checkInDate : '';
    pol.check_out = (pol.check_out) ? pol.check_out : '';

    setCriteria({nights: getNumDays(checkInDate, checkOutDate)});

    BookingSummary.handleCheckIn(checkInDate, pol.checkInDate);
    BookingSummary.handleCheckOut(checkOutDate, pol.check_out);
    //BookingSummary.handleTotalTax((rateData.totalAfterTax - rateData.totalBeforeTax), rateData.currencyCode);

    if (!($_PAGE_.is('.modify-page'))) {
      BookingSummary.handleAdults(this._params.adults);
      BookingSummary.handleChildren(this._params.children);
      BookingSummary.handleRooms(this._params.rooms);
      BookingSummary.handleNights(getCriteria().nights);
    }

    if (rateData.depositPolicy) {
      BookingSummary.handleCancelPolicy(rateData.cancelPolicy + '</br></br>' + rateData.depositPolicy);
    } else {
      BookingSummary.handleCancelPolicy(rateData.cancelPolicy);
    }
    $('.modal-section.tax-info div').not('.estimated-taxes').remove();

    RateDetails.fillTaxes(rateData.taxList, rateData.currencyCode, null, getBrand(true),rateData);
    if (rateData.fnsRatePlan) {
      let fees = 0,
        currency;
      if (rateData.taxList[0]) {
        fees = parseFloat(rateData.taxList[0] ? rateData.taxList[0].taxPercent : 0);
        fees *= getCriteria().nights;
        currency = rateData.taxList[0].taxPercent.replace(/[0-9\W]/g, '');
      }
      RateDetails.handleGoFree(formatNumber(parseInt(rateData.totalFnsPoints)), fees, currency);
    }
  }

  populateBreakDown(rateData) {
    let dateRateMapObj = rateData.dateRateMap,
      currencyCode = rateData.currencyCode,
      $brkdwnheader = $('.rate-summary-lightbox').find('.rate-breakdown .room-nights-header'),
      $brkdwnvalue = $('.rate-summary-lightbox').find('.rate-breakdown .room-nights-value');
    $brkdwnheader.find('li:not(.breakdown-label)').remove();
    $brkdwnvalue.find('li:not(.breakdown-label)').remove();
    for (let propName in dateRateMapObj) {
      let propValue = dateRateMapObj[propName];
      $brkdwnheader.append(`<li class=''>${formatDateForPrinting(propName, 'weekdayCompact')}</li>`);
      $brkdwnvalue.append(`<li class="dollar-amount">${printDollarAmount(parseFloat(propValue), currencyCode, true)}</li>`);
    }
  }

  showTaxModal() {
    $('#rateSummaryDetail').modal('show');
    if (isMobileWidth()) {
      $('#rateSummaryDetail').height(getWindowHeight()).css({'top': 0,'position': 'fixed','margin-top': 0});
    }
  }

  showLightbox(msg) {
    $_GENERIC_LIGHTBOX_.modal({
      show: true,
      remote: false
    }).find('.modal-content')
      .html(msg);
  }

  async promptLogin() {
    bookDataBeforeSSOLogin();
    Login.redirectToOktaLogin(window.location.href);
  }

  getRoom(roomTypeCode) {
    return this._roomsAndRates.rooms.find(roomObj => {
      return roomObj.roomTypeCode === roomTypeCode;
    });
  }

  getRateFromRoom(room, ratePlanId) {
    return room.rates.find(rateObj => {
      return rateObj.ratePlanId === ratePlanId;
    });
  }

  //For now just pick up the first 2 rooms from the getRoomsRate Service
  sortAndReduceRooms() {
    this._roomsToDisplay.push(this._roomsAndRates.rooms[0]);
    if (this._roomsAndRates.rooms[1] != undefined) {
     this._roomsToDisplay.push(this._roomsAndRates.rooms[1]);
    }
  }

  reduceRatesToDisplay() {
    this._roomsToDisplay.forEach((room) => {
      let cashRates = [], pointsRates = [];
      room.rates.forEach((rateData, i) => {
        let isPointRate = (rateData.pacRatePlan || rateData.fnsRatePlan);
        if (pointsRates.length <= 4 && isPointRate) {
          pointsRates.push(rateData);
        } else if(cashRates.length < 2) {
          cashRates.push(rateData);
        }
      });
      room.rates = (this._isPointsRateSelected) ? pointsRates : cashRates;
    });
  }

  //The updateRatesData() has issue so deleting manually the datalayer hiddenRates object
  analyticsDataLayerUpdate() {
    if(window.digitalData && window.digitalData.roomsRates && window.digitalData.roomsRates.display){
      window.digitalData.roomsRates.display.hiddenRates = [];
    }
  }

  satelliteTracker() {
    if (typeof window._satellite !== 'undefined') {
      window._satellite.track('featuredRoomsRatesView');
    }
  }

  updateAnalytics(){
    //Load dataLayer
    Analytics.updateRoomsData();
    Analytics.updateRatesData();
    this.analyticsDataLayerUpdate();
    //Notify dataLayer is ready to analytics
    this.satelliteTracker();
  }

  hideComponent() {
    //Hide the link from left nav
    let sectionTitleId = this.$mainEl.find('.section-title-wrapper .section-title-component').attr('id');
    $('#sidebar li a[href*=' + sectionTitleId + ']').parent().addClass('hide');
    $('.left-nav-list__items li a[href*=' + sectionTitleId + ']').parent().addClass('hide');
    //Hide the component
    this.$mainEl.addClass('hide');
  }

  showComponentLoading() {
    let sectionTitleId = this.$mainEl.find('.section-title-wrapper .section-title-component').attr('id');
    $('#sidebar li a[href*=' + sectionTitleId + ']').parent().removeClass('hide');
    this.$mainEl.removeClass('hide');
    this.$mainEl.find('#loader').addClass('loader');
    this.$mainEl.find('.rooms-slide.by-room').addClass('hide');
    this.$mainEl.find('.see-all-rates-wrapper').addClass('hide');
  }
}

export default new FeaturedRoomsRates();
