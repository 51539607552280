
import { callService } from '../base/utils';
import { setSearchOverview, getSearchOverview } from '../base/session-handler';
import { _LOCALE_ } from '../base/vars';
import {EventHandler} from "../base/utils";

class OverviewService {
  async getOverview(propertyId, rts = null) {
    if (this.overviewData && this.overviewData.propertyId === propertyId) {
      EventHandler.send(EventHandler.property.search.success, null, this.overviewData);
      return this.overviewData;
    }

    const searchOverview = getSearchOverview();
    if (searchOverview && searchOverview.propertyId === propertyId) {
      this.overviewData = searchOverview;
      EventHandler.send(EventHandler.property.search.success, null, this.overviewData);
      return this.overviewData;
    }

    if (this.pendingPromise) {
      const result = await this.pendingPromise;
      EventHandler.send(EventHandler.property.search.success, null, result);
      return result;
    }

    try {
      this.pendingPromise = this.fetchOverview(propertyId, rts);
      const result = await this.pendingPromise;
      this.pendingPromise = null;
      EventHandler.send(EventHandler.property.search.success, null, result);
      return result;
    } catch (error) {
      this.pendingPromise = null;
      throw error;
    }
  }

  async fetchOverview(propertyId, rts) {
    const params = {
      isOverviewNeeded: true,
      propertyId: propertyId,
      isAmenitiesNeeded: true,
      channelId: 'tab',
      language: _LOCALE_,
      isRoomAmenitiesNeeded: true,
    };
    if (rts !== null) {
      params.roomTypes = rts;
    }
    try {
      const res = await callService('overview', params);
      if (res && res.status === 'OK') {
        this.overviewData = res.properties[0];
        setSearchOverview(this.overviewData, true);
        EventHandler.send(EventHandler.property.search.success, null, this.overviewData);
        return this.overviewData;
      } else {
        throw new Error('Overview request was interrupted');
      }
    } catch (error) {
      console.error('Error fetching overview:', error);
      throw error;
    }
  }
}

export default new OverviewService();
